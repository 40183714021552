import * as React from 'react'
import Layout from '../components/Layout/Layout'

const donateThankYouPage = () => {
	return (
		<Layout>
			<div className={`bg-boswell-handwriting min-h-screen-50 p-4 md:p-12`}>
				<p className={`text-xl mb-4`}>Your support of the Boswell Book Festival is gratefully acknowledged.</p>
				<p className={`text-xl mb-4`}>Thank you for helping this unique festival to thrive, we couldn't do it without you.</p>
				<p className={`mt-12`}>Caroline Knox</p>
				<p>Festival Director</p>
			</div>
		</Layout>
	)
}

export default donateThankYouPage
